@import "~antd/dist/antd.less";

.logo {
  margin-top: 15px;
  margin-bottom: 15px;
  color: #fff;
  font-weight: bold;
  font-size: 20px;
  text-align: center;
}

.CalendarPage {
  // height:100%;
  // min-height: 100vh;
  display: flex;
  flex-direction: column;
  // align-items: center;
  // justify-content: center;
  // text-align: center;
}

.PlanificationPage {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 100%;
}
.PlanificationPageContainer {
  background-color: rgb(187, 187, 187);
  flex: 1;
  // max-width: 1500px;
  width: 100%;
  margin: auto;
  padding: 0px 10px 10px 10px;
}
.FeuilleDeRoutePage {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 100%;
}

.FeuilleDeRouteContainer {
  background-color: rgb(187, 187, 187);
  flex: 1;
  width: 100%;
  margin: auto;
  padding: 10px 10px 10px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.FeuilleDeRoutePageContent {
  max-width: 1500px;
}
.FeuillesDeRoutePage {
  display: flex;
  // flex-direction: column;
  flex: 1;
  min-height: 100%;
  background-color: rgb(187, 187, 187);
  padding: 10px;
}
.FeuillesDeRoutePageContainer {
  width: 100%;
  background-color: white;
  padding: 20px;
}
.UtilisateursPage {
  display: flex;
  // flex-direction: column;
  flex: 1;
  min-height: 100%;
  background-color: rgb(187, 187, 187);
  padding: 10px;
}
.UtilisateursPageContainer {
  width: 100%;
  background-color: white;
  padding: 20px;
}
.RapportsPage {
  display: flex;
  // flex-direction: column;
  flex: 1;
  min-height: 100%;
  background-color: rgb(187, 187, 187);
  padding: 10px;
}
.RapportsPageContainer {
  width: 100%;
  background-color: white;
  padding: 40px;
}
.HeuresPage {
  display: flex;
  flex: 1;
  min-height: 100%;
  background-color: rgb(187, 187, 187);
  padding: 10px;
}
.HeuresPageContainer {
  width: 100%;
  background-color: white;
  padding: 20px;
}
.LoginPage {
  display: flex;
  // flex-direction: column;
  flex: 1;
  min-height: 100%;
  background-color: rgb(187, 187, 187);
  padding: 10px;
}
.LoginPageContainer {
  width: 100%;
  background-color: white;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.events {
  margin: 0;
  padding: 0;
  list-style: none;
}
.events .ant-badge-status {
  width: 100%;
  overflow: hidden;
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ant-card-head-title {
  padding: 0px;
}
.ant-back-top {
  right: 50px;
}

.ant-picker-cell-selected .ant-badge-status-text,
.ant-picker-cell-selected .ant-picker-calendar-date-value,
.ant-picker-cell-selected .ant-badge {
  color: white !important;
}

.clickable-row {
  cursor: pointer;
}

.ant-table-tbody > tr.ant-table-row-selected > td,
.ant-table-tbody > tr.ant-table-row-selected:hover > td {
  background: #00152915; /* Change this to your desired background color */
}

@primary-color: #001529;